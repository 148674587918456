<template>
  <div>
    <div class="flex flex-no-wrap">
      <div class="w-1/3 p-5">
        <h1>{{ complianceCount.inReview }}</h1>
        <h3>Ready for review</h3>
      </div>
      <div class="w-1/3 p-5">
        <h1>{{ complianceCount.completed }}</h1>
        <h3>Completed</h3>
      </div>
      <!-- <div class="w-1/3 p-5">
        <h1>34</h1>
        <h3>4+ missed calls</h3>
      </div> -->
    </div>
    <vx-card style="padding-left: 10px">
      <div class="lg:hidden mt-3">
        <div class="mb-2 flex flex-wrap">
          <div class="w-1/2 mb-2 pr-2">
            <vs-select class="select" autocomplete v-model="selectedMonth" width="100%" id="compliance-list-month" :disabled="!serverResponded">
              <vs-select-item :key="index" :value="item.value" :text="item.text"
                v-for="(item, index) in monthDropdown" />
            </vs-select>
          </div>
          <div class="w-1/2 mb-2 pr-2">
            <vs-select class="select" :placeholder="currentYear" autocomplete v-model="selectedYear" width="100%"
              id="compliance-list-year" :disabled="!serverResponded">
              <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in yearDropdown" />
            </vs-select>
          </div>
          <div class="w-1/2 mb-2 pr-2">
            <vs-select placeholder="All Status" width="100%" id="compliance-list-clinic" autocomplete
              v-model="dataTableParams.status" :disabled="!serverResponded">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in statusList" />
            </vs-select>
          </div>
          <div class="w-1/2 mb-2 pr-2">
            <vs-select placeholder="All Clinics" width="100%" id="compliance-list-clinic" autocomplete
              v-model="dataTableParams.clinic" :disabled="!serverResponded">
              <vs-select-item :key="index" :value="item.clinicId" :text="item.name" v-for="(item, index) in clinics" />
            </vs-select>
          </div>
          <div class="w-full mb-2 pr-2">
            <vs-select placeholder="All Organizations" width="100%" id="compliance-list-clinic" autocomplete
              v-model="dataTableParams.organizationId" :disabled="!serverResponded">
              <vs-select-item :key="index" :value="item._id" :text="item.name" v-for="(item, index) in organizations" />
            </vs-select>
          </div>
          <div class="w-full mb-2 pr-2">
            <vs-input
              icon="search"
              style="width: 100%"
              placeholder="Search"
              v-model="searchInputString"
              @input="handleSearch"
              :disabled="!serverResponded"/>
          </div>
        </div>
        <ul>
          <li :key="indextr" v-for="(tr, indextr) in monthlyCompliances">
            <div class="rounded mb-2 p-2 mobile-text relative" style="border: 1px solid #e8e8e8">
              <div>
                <div>
                  <h4 class="sm:text-base mr-2 mobile-text w-3/5 text-sm">
                    {{
                        monthlyCompliances[indextr].clinicName
                          ? monthlyCompliances[indextr].clinicName
                          : "N/A" | capitalize
                    }}
                  </h4>
                </div>
                <div class="absolute top-0 right-0">
                  <h4 class="mobile-text">
                    <div
                      :class="monthlyCompliances[indextr].status.toLowerCase() + ' md:text-base text-sm rounded-full text-center px-3 py-1 inline-block font-semibold'">
                      {{ getStatusData(monthlyCompliances[indextr].status) }}
                    </div>
                  </h4>
                </div>
              </div>
              <div class="mobile-text">
                <p class="sm:text-base text-sm mt-2">
                  {{
                      getQuarter(monthlyCompliances[indextr].quarter) +
                      " " +
                      monthlyCompliances[indextr].orderYear
                  }}
                </p>
              </div>
              <div class="absolute bottom-0 right-0">
                <a v-if="monthlyCompliances[indextr].status == 'upNext'" class="m-2 text-sm underline"
                  @click="startComplianceData(monthlyCompliances[indextr]._id)">Start Now</a>
                <vs-button v-else class="sm:text-base text-sm h-6 px-3 py-0 m-1" icon-pack="feather" type="border"
                  @click="startComplianceData(monthlyCompliances[indextr]._id)">View</vs-button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="data-list-container">
        <vs-table
          class="hidden lg:block"
          ref="table"
          :sst="true"
          :data="monthlyCompliances"
          :max-items="dataTableParams.limit"
          :noDataText="noDataText">
          <div slot="header" class="mb-2 flex w-full justify-between">
            <div class="flex">
              <div class="pr-2 w-1/8">
                <vs-select class="select" autocomplete v-model="selectedMonth" width="100%" id="compliance-list-month" :disabled="!serverResponded">
                  <vs-select-item :key="index" :value="item.value" :text="item.text"
                    v-for="(item, index) in monthDropdown" />
                </vs-select>
              </div>
              <div class="pr-2 w-1/8">
                <vs-select class="select" :placeholder="currentYear" autocomplete v-model="selectedYear" width="100%"
                  id="compliance-list-year" :disabled="!serverResponded">
                  <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in yearDropdown" />
                </vs-select>
              </div>
              <div class="pr-2 w-1/8">
                <vs-select placeholder="All Clinics" width="100%" id="compliance-list-clinic" autocomplete
                  v-model="dataTableParams.clinic" :disabled="!serverResponded">
                  <vs-select-item :key="index" :value="item.clinicId" :text="item.name"
                    v-for="(item, index) in clinics" />
                </vs-select>
              </div>
              <div class="pr-2 w-1/8">
                <vs-select placeholder="All Organizations" width="100%" id="compliance-list-clinic" autocomplete
                  v-model="dataTableParams.organizationId" :disabled="!serverResponded">
                  <vs-select-item :key="index" :value="item._id" :text="item.name"
                    v-for="(item, index) in organizations" />
                </vs-select>
              </div>
              <div class="pr-2 w-1/8">
                <vs-select placeholder="All Status" width="100%" id="compliance-list-clinic" autocomplete
                  v-model="dataTableParams.status" :disabled="!serverResponded">
                  <vs-select-item :key="index" :value="item.value" :text="item.text"
                    v-for="(item, index) in statusList" />
                </vs-select>
              </div>
            </div>

            <div>
              <div class="pr-2 w-1/8">
                <vs-input
                  icon="search"
                  class="search-input"
                  :disabled="!serverResponded"
                  v-model="dataTableParams.search"
                  @input="handleSearch"
                  placeholder="Search..."
                />
              </div>
            </div>
          </div>

          <template slot="thead">
            <vs-th class=" w-1/3">CLINIC NAME</vs-th>
            <vs-th class=" w-1/6">QUARTER</vs-th>
            <vs-th class=" w-1/6">YEAR</vs-th>
            <vs-th class=" w-1/6">STATUS</vs-th>
            <vs-th>ACTIONS</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in monthlyCompliances">
              <vs-td :data="data[indextr].clinicName">{{
                  data[indextr].clinicName
              }}</vs-td>
              <vs-td :data="data[indextr].month">{{
                  getQuarter(data[indextr].quarter)
              }}</vs-td>
              <vs-td :data="data[indextr].year">{{
                  data[indextr].orderYear
              }}</vs-td>
              <vs-td :data="data[indextr].status">
                <div
                  :class="data[indextr].status.toLowerCase() + ' rounded-full text-sm text-center px-3 py-1 inline-block font-semibold'">
                  {{ getStatusData(data[indextr].status) }}
                </div>
              </vs-td>
              <vs-td :data="data[indextr]._id">
                <vs-button size="small" icon-pack="feather"
                  @click="startComplianceData(data[indextr]._id)">Start</vs-button>
                <!-- <vs-button
           
                size="small"
                type="border"
                icon-pack="feather"
                class="mr-2"
                @click="viewComplianceData(data[indextr]._id)"
                >View</vs-button
              > -->
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <div class="m-2 flex justify-end">
          <span class="mr-2"></span>
          <pagination
            class="mt-3"
            :isFetching="!serverResponded"
            :hasPrev="hasPrev"
            :hasNext="hasNext"
            @handleOnChangePage="handleChangePage"/>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import Pagination from "@/views/general-component/pagination.vue";
import _ from "lodash";

export default {
  props: {},
  inject: ['getRoleRouteName'],
  components: {
    Pagination
  },
  data() {
    return {
      monthlyCompliances: [],
      yearDropdown: [],
      statusList: [
        { text: "All Status", value: null },
        { text: "Up next", value: "upNext" },
        { text: "Ready for review", value: "inReview" },
        { text: "Change requested", value: "changeRequested" },
        { text: "Completed", value: "completed" },
        { text: "Skipped", value: "skipped" },
        { text: "Missed", value: "failed" },
      ],
      monthDropdown: [
        { text: "Q1 (Jan - Mar)", value: "1" },
        { text: "Q2 (Apr - Jun)", value: "2" },
        { text: "Q3 (Jul - Sep)", value: "3" },
        { text: "Q4 (Oct - Dec)", value: "4" },
      ],
      clinics: [{ clinicId: null, name: "All Clinics" }],
      organizations: [],
      complianceCount: {
        completed: 0,
        inReview: 0,
      },
      dataTableParams: {
        organizationId: "all",
        search: "",
        sort: "_id",
        dir: "desc",
        page: 1,
        status: "inReview",
        limit: 25,
        clinic: null,
      },
      serverResponded: false,
      currentYear: new Date().getFullYear(),
      selectedYear: new Date().getFullYear(),
      selectedMonth: "",
      searchInputString: null,
      userId: "",
      hasPrev: false,
      hasNext: false,
      noDataText: "Loading..",
    };
  },
  methods: {
    ...mapActions("monthlyCompliance", [
      "getSuperAdminCompliance",
      "startCompliance",
      "getSuperAdminComplianceCount",
    ]),
    ...mapActions("organization", [
      "fetchOrganizationAssignList",
      "fetchSuperAdminOrganizationClinics",
    ]),
    async getComplianceCount(month, year) {
      const res = await this.getSuperAdminComplianceCount({
        month: month,
        year,
      });
      this.$vs.loading.close();
      this.complianceCount = res.data.data.docs;
    },
    getQuarter(month) {
      return _.filter(this.monthDropdown, ["value", month])[0].text;
    },
    getYearDropdown() {
      for (let i = 2020; i <= this.currentYear; i++) {
        this.yearDropdown.push(i);
      }
    },
    getStatus(status) {
      return _.find(this.statusList, ["value", status]).text;
    },
    async startComplianceData(id) {
      this.$router.push({
        name: "superAdminComplianceReview",
        params: { clinicId: id },
      });
    },
    async viewComplianceData(id) {
      this.$router.push({
        name: this.getRoleRouteName("ComplianceReview"),
        params: { clinicId: id },
      });
    },
    getOrganizationAllClinicsList(id) {
      this.fetchSuperAdminOrganizationClinics({
        organizationId: id || "all",
      }).then((res) => {
        this.$vs.loading.close();
        this.clinics = [
          { clinicId: null, name: "All Clinics" },
          ...res.data.data.docs,
        ];
      });
    },
    async getOrganizationList() {
      const res = await this.fetchOrganizationAssignList({
        id: this.userId,
      });
      this.organizations = [
        { _id: "all", name: "All Organizations" },
        ...res.data.data,
      ];
      this.serverResponded = true;
    },
    async getMonthlyComplianceList(options = {}) {
      try {
        this.$vs.loading();
        this.monthlyCompliances = []
        this.noDataText = "Loading...";

        const { data } = await this.getSuperAdminCompliance({
          ...this.dataTableParams,
          month: this.selectedMonth,
          year: this.selectedYear,
          ...options
        })

        this.$vs.loading.close();
        this.noDataText = "No Quarterly Compliance Found";
        
        this.monthlyCompliances = data.data.docs;
        this.hasNext = data.data.hasNext
        this.hasPrev = data.data.hasPrev

        if (options.page) this.dataTableParams.page = options.page
      } catch (err) {
        this.noDataText = "No Quarterly Compliance Found";
        this.$vs.loading.close();
      } finally {
        this.serverResponded = true;
      }
    },
    handleSearch: _.debounce(function (searchString) {
      this.serverResponded = false;
      this.$refs.table.currentx = 1;
      this.getMonthlyComplianceList({
        search: searchString,
        page: 1
      });
    }, 500),
    handleChangePage(page) {
      let payload = {
        next: true,
        id: this.monthlyCompliances[this.monthlyCompliances.length - 1]._id,
        page: this.dataTableParams.page + 1
      }

      if (page === 'prev') {
        payload = {
          prev: true,
          id: this.monthlyCompliances[0]._id,
          page: this.dataTableParams.page - 1
        }
      }

      this.serverResponded = false;
      this.getMonthlyComplianceList(payload)
    },
    getStatusData(data) {
      const status = {
        upNext: 'Up Next',
        failed: 'Missed',
        skipped: 'Skipped',
        inReview: 'Ready for Review',
        changeRequested: 'Change Requested',
        completed: 'Completed',
      }

      return status[data];
    },
  },
  watch: {
    selectedMonth: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedMonth = newVal;
        this.getMonthlyComplianceList();
        this.getComplianceCount(newVal, this.selectedYear);
      }
    },
    selectedYear: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedYear = newVal;
        this.getMonthlyComplianceList();
        this.getComplianceCount(this.selectedMonth, newVal);
      }
    },
    "dataTableParams.clinic": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.clinic = newVal;
        this.getMonthlyComplianceList();
      }
    },
    "dataTableParams.organizationId": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.organizationId = newVal;
        this.getOrganizationAllClinicsList(newVal);
        this.getMonthlyComplianceList();
      }
    },
    "dataTableParams.status": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.status = newVal;
        this.getMonthlyComplianceList();
      }
    },
  },
  created() {
    this.userId = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    )._id;
    this.selectedMonth = this.monthDropdown[moment(new Date()).quarter() - 1].value;
    this.getYearDropdown();
    this.getOrganizationList();
    this.getOrganizationAllClinicsList();
    this.getMonthlyComplianceList();
    this.getComplianceCount(this.selectedMonth, this.selectedYear);
  },
};
</script>

<style lang="scss">
.data-list-container {
  .search-input {
    .vs-input--input {
      border-radius: 20px;

      &:focus {
        border: 1px solid rgba(0, 0, 0, 0.2) !important;
        -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .03);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .03);
      }
    }

    .vs-con-input {
      .vs-input--icon {
        top: auto;
        left: 8px;
        border-right: none;
      }
    }
  }
}

.select {
  width: 65px;
  margin-right: 5px;
}

.selectClinic {
  width: 120px;
  margin-right: 5px;
}

.week-select {
  width: 195px;
  margin-right: 5px;
}

td {
  font-size: 15px !important;
  white-space: nowrap !important;
}

.closeTd {
  display: none;
}

.openTd {
  display: block;
}

.detailText {
  cursor: pointer;
  color: blue;
}

.coloredTr {
  background: aliceblue;
}

.weeklyViewButton {
  border: 1px solid;
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  cursor: pointer;
  border-color: #aaa !important;
  color: #666 !important;
  box-shadow: 0 1px 3px #eee;
}

.monthlyViewButton {
  border: 1px solid;
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
  border-color: #aaa !important;
  color: #666 !important;
  box-shadow: 0 1px 3px #eee;
}

.selectedMonthView {
  background: #aaa;
  color: #fff;
}

.selectedView {
  background: #aaa;
  color: #fff;
}

.selectedView .svgIcon {
  color: #fff;
}

.upnext,
.inreview {
  background-color: #E5F8EE;
  color: #84CDB8;
}

.skipped,
.completed {
  background-color: #E2E8E6;
  color: #074230;
}

.failed {
  background-color: #FBE4E4;
  color: #EA5455;
}

.changerequested {
  background-color: #FF9F43;
  color: #FFF2E3;
}
</style>

